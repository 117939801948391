<template>
  <div class="text-left">
    <BaseHeader :title="'Sites'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-body text-right d-flex" style>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="my-auto ml-2"
            size="small"
            @click.stop="
              $store.state.dialog = true;
              this.isEditMode = false;
            "
            >New</v-btn
          >
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="sites"
          :search="$store.state.search"
        >
          <template v-slot:[`item.deleted`]="{ item }">
            <v-chip v-if="item.deleted" class="ma-2" color="error" small
              >Inactive</v-chip
            >
            <v-chip v-else class="ma-2" color="primary" text-color="white" small
              >Active</v-chip
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn x-small fab elevation="0" @click="editMode(item)">
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
            <!-- <v-btn color="error" class="white--text" fab x-small>
                <v-icon small class="white--text">mdi-delete</v-icon>
              </v-btn> -->
          </template>
        </v-data-table>
        <!-- /datatable -->
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="sitesForm.busy">
          <v-card-title class="text-h5"
            >{{ isEditMode ? "Edit" : "New" }} Site</v-card-title
          >
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill"
              ref="sitesForm"
              @submit.prevent="createSite"
            >
              <div class>
                <div class="text-left mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Site Name"
                      name="site_name"
                      type="site_name"
                      v-model="sitesForm.name"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="site_name"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Site Url"
                      name="url"
                      type="link"
                      v-model="sitesForm.url"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="url"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Gateway Checkout Url"
                      name="gateway_checkout_url"
                      type="link"
                      v-model="sitesForm.gateway_checkout_url"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="gateway_checkout_url"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Application Url"
                      name="application_url"
                      type="link"
                      v-model="sitesForm.application_url"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="application_url"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Site Email"
                      name="email"
                      type="email"
                      v-model="sitesForm.email"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="email"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Tawk Code"
                      name="tawk_code"
                      type="text"
                      v-model="sitesForm.tawk_code"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="tawk_code"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" class="text-left">
                    <label for="client_id">Client</label>
                    <v-select
                      dense
                      outlined
                      hide-details
                      placeholder="Client: Start typing to search"
                      v-model="sitesForm.client_id"
                      :items="clients"
                      item-text="first_name"
                      item-value="id"
                    ></v-select>
                    <!-- <vue-select
                      label="first_name"
                      required
                      v-model="sitesForm.client_id"
                      placeholder="Client: Start typing to search"
                      :options="clients"
                      :reduce="(client) => client.id"
                    >
                      <template v-slot:option="option"
                        >{{ option.first_name || "" }}
                        {{ option.last_name || "" }}</template
                      >
                    </vue-select> -->
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Captcha Site Key"
                      name="site_key"
                      type="text"
                      v-model="sitesForm.site_key"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="site_key"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Captcha Secret Key"
                      name="secret_key"
                      type="text"
                      v-model="sitesForm.secret_key"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="secret_key"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-checkbox
                      class="mt-0"
                      v-model="is_admin_system"
                      :label="`Is Admin System`"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="6" v-if="!is_admin_system">
                    <v-select
                      dense
                      outlined
                      label="Select admin system"
                      :items="adminSystems"
                      item-text="application_url"
                      item-value="admin_system"
                      name="admin_system"
                      v-model="sitesForm.admin_system"
                      hide-details
                    ></v-select>
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="admin_system"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" class="text-left">
                    <v-btn
                      :disabled="sitesForm.busy"
                      type="submit"
                      color="primary"
                      >{{ isEditMode ? "Update" : "Create" }} Site</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Sites",
  computed: {
    ...mapState("sites", [
      "sitesForm",
      "loading",
      "sites",
      "headers",
      "adminSystems",
    ]),
    ...mapState("clients", ["clients"]),
  },
  data() {
    return {
      countries: [],
      activeItem: {},
      isEditMode: false,
      is_admin_system: null
    };
  },

  methods: {
    ...mapActions("sites", ["getSites", "getGetAdminSystems"]),
    ...mapActions("clients", ["getClients"]),
    editMode(item) {
      console.log("this.sitesForm", this.sitesForm);
      this.activeItem = item;
      Object.assign(this.sitesForm, { ...item });
      this.is_admin_system =
        this.sitesForm.is_admin_system == "-1" ? false : true;
      this.sitesForm.site_id = item.id;
      this.isEditMode = true;
      this.$store.state.dialog = true;
    },
    createSite() {
      if (this.is_admin_system) {
        this.sitesForm.is_admin_system = "1";
        this.sitesForm.admin_system = "0";
      } else {
        this.sitesForm.is_admin_system = "-1";
      }

      console.log("this.sitesForm", this.sitesForm);
      // return 
      // save new sites as without admin system always
      // this.sitesForm.is_admin_system = "0";
      // this.sitesForm.client_id = "6";
      // this.sitesForm.admin_system = "160";
      this.isEditMode
        ? this.sitesForm
            .put(`${this.$baseUrl}/Sites/Site`)
            .then(() => {
              this.getSites();
              this.getGetAdminSystems();
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: "Site updated successifully.",
                style: "success",
              });
              this.isEditMode = false;
              this.sitesForm.reset();
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : "Error while updating site",
                style: "danger",
              });
            })
        : this.sitesForm
            .post(`${this.$baseUrl}/Sites/Site`)
            .then(() => {
              this.getSites();
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: "Site created successifully.",
                style: "success",
              });
              this.isEditMode = false;
              this.sitesForm.reset();
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : "Error while creating site",
                style: "danger",
              });
            });
    },
  },
  async mounted() {
    await this.getSites();
    await this.getClients();
    await this.getGetAdminSystems();
  },
};
</script>